<template>
  <div class="content">
    <top-title>
      <span slot="left">实名认证</span>
      <span slot="right">实名认证</span>
    </top-title>
    <!-- <el-row class="part1" type="flex" justify="space-between" v-if="basic.realType">
      <el-col :span="24" :md="24" class="col-md9">
        <div class="data border">
          <div class="inner layout-flex">
            <div class="col-name">账号昵称</div>
            <div class="col-content">
              <span>{{ basic.nickName }}</span>
            </div>
          </div>
          <div class="inner layout-flex">
            <div class="col-name">头像</div>
            <div class="col-content">
              <img :src="basic.avatar" width="80px" height="80px" />
            </div>
          </div>
          <div class="inner layout-flex">
            <div class="col-name">联系手机</div>
            <div class="col-content">
              <span>{{ basic.phone }}</span>
            </div>
          </div>
          <div class="inner layout-flex">
            <div class="col-name">联系邮箱</div>
            <div class="col-content">
              <span>{{ basic.emailFormat }}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>-->
    <el-row class="part1" type="flex" justify="space-between">
      <el-col :span="24" :md="24" class="col-md9">
        <div class="data border">
          <div class="inner2">
            <div class="imgBox">
              <img width="300px" height="300px" src="@/assets/img/mini.jpg" />
            </div>
            <p class="tips">扫描二维码 前往手机端完善或修改实名信息</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TopTitle from "@/components/common/top-title";
import TopTitleLink from "@/components/common/top-title-link";
export default {
  data () {
    return {
      basic: {},
    };
  },
  created () {
    this.basic = JSON.parse(localStorage.getItem("userInfo"));
  },

  components: {
    TopTitle,
    TopTitleLink,
  },

  computed: {},

  methods: {
    emailFormat () {
      if (this.basic.email != "") {
        const len = this.basic.email.indexOf("@") - 3;
        const arr = [];
        for (let i = 0; i < len; i++) arr.push("*");
        this.basic.emailFormat =
          this.basic.email.substr(0, 3) +
          arr.join("") +
          this.basic.email.substr(
            this.basic.email.indexOf("@"),
            this.basic.email.length
          );
      }
      return this.basic.emailFormat;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin tit($bottom: 15px) {
  margin-bottom: $bottom;
  font-size: 16px;
  color: #666;
  font-weight: bold;
}
@mixin layout-flex() {
  margin-bottom: 20px;
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}
.content {
  width: 100%;
  .part1 {
    @include layout-flex();
    .col-md9 {
      margin-bottom: 20px;
    }
    .data {
      margin: 0 0 20px 0;
      background: #fff;
      .tit {
        @include tit(0);
        padding: 15px;
        border-bottom: 1px solid #ebeef5;
      }
      .inner {
        padding: 15px;
        .col-name {
          margin-right: 20px;
          color: #999;
          font-size: 14px;
          width: 7%;
        }
        .col-content {
          span {
            font-size: 14px;
            color: #666;
            font-weight: bold;
          }
        }
      }
      .inner2 {
        text-align: center;
        padding: 35px 15px 15px;
        margin: 10px auto;
        width: 600px;
        .tips {
          padding: 25px 0;
          color: #666;
          font-size: 14px;
        }
      }
    }
  }
}

em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: '已认证';
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: '未认证';
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
</style>